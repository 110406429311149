@import (reference) "../Frontend/Forms-reference.less";

user-prompt {
    .fullscreen();

    section {
        flex-direction: row !important;
        height: auto !important;
        justify-content: flex-end !important;
        margin-bottom: 0 !important;
        padding: 20px !important;
    }

    header {
        border: none !important;

        h1 {
            color: black;
            font-weight: 400;
            margin: 10px 0 !important;
        }

        p {
            font-weight: 300;
            color: black;
            margin-top: 20px;
        }
    }
}