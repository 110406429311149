admin-navigation {
    display: flex;
    flex: 0 0 60px;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #ccc;
    background-color: var(--background-color);

    nav {
        max-width: 1024px;
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
        
        a {
            display: block;
            margin: 10px;
            text-decoration: none;
            color: var(--foreground-color);

            &[active] {
                font-weight: bold;
            }
        }
    }

    .profile {
        color: var(--foreground-color);
        display: flex; 
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 15px;

        img {
            margin: 0px 10px;
            height: 30px;
            border-radius: 50%;
        }
    }
}
