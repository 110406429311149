create-session {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    [name=code-box] {
        display: none;
        margin: 10px;

        input {
            font-size: 22px;
            width: 100px;
            text-align: center;
        
        }
    }
}