@import (reference) "../../Features/Frontend/Forms-reference.less";

body[name=login] {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}   

.auth-form {
    max-width: 400px;
    width: 100%;

    > form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px;
        width: 100%; 
        background: #f4f4f4;
        border-radius: 5px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        position: relative;
        z-index: 1;
        overflow: hidden;
        margin: 40px 0;

        input {
            width: 100%;
            height: 40px;
            padding: 0 10px;
            border: 1px solid #f4f4f4;
            border-radius: 5px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            margin: 10px 0;
            font-size: 14px;
            font-weight: 300;
            color: #636363;
            background: #fff;
            outline: none;
        }
    }
    
}