:root {
    --primary: black;
}


// [environment=Local] {
//     --background-color: #af52de;
//     --foreground-color: white;
// }

[environment=Local] {
    --background-color: #8a8a8a;
    --foreground-color: white;
}

[environment=Test] {
    --background-color:#009879;
    --foreground-color: white;
}

[environment=Production] {
    --background-color:  #5857d8;
    --foreground-color: white;
}


body,
html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

body {
    font-family: sans-serif;
    background-color: white;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
}

* {
    box-sizing: border-box;
}

live-support {
    width: 100%;
    height: 100%;
    --border-radius: 0px;
}

.button-bar {
    width: 100%;
    margin: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
        margin: 10px;
    }
}

.section-header {
    width: 100%;
    margin: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fieldset-section-header {
    width: 100%;
    margin: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    > div {
        width: 100%;
        margin-top: 10px;
        padding: 0 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:first-of-type {
            margin-top: 0;
        }

        form:first-of-type {
            button {
                margin: 0;
            }
        }

        button {
            // background-color: white;
            // color: black;
            // border:none;
            // 
            // margin: 0 0 0 10px;
            height: 100%;
            flex: 1 1; 
        }

        fieldset {
            flex: 1 1;
            display: flex;
            background-color: #e9e9e9; 
            border: none;
            border-radius: 5px;
            height: 80px;
            justify-content: stretch;
            padding: 10px;

            form {
                flex: 1 1;
                justify-content: stretch;
                display: flex;
            }

            input, select, button {
                font-size: 14px;
                padding: 5px 10px;
            }

            input, select {
                flex: 1 1 80%;
                border-radius: 5px;
                border: none;
            }

            

            legend {
                background-color: #e9e9e9;
                padding: 5px 10px;
                border-radius: 5px;
                font-size: 14px;
                font-weight: 100;
                margin-bottom: -8px;
            }
        }

        > div {
            justify-content: stretch;
            display: flex;
            
            
        }

        &.filter {
            // background-color: #e9e9e9;
            // width: calc(100% - 10px);
            // border-radius: 5px;
            // padding: 10px;
            // margin: 0;

            input {
                font-size: 16px;
                //width: calc(100% - 60px);
                padding: 5px 10px;
                text-align: center;
                border: none;
            }

            button {
                width: 60px;
            }
        }
    }
}

table {
    border-collapse: collapse;
    margin: 0 0 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 400px;
    width: 100%;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    font-family:
        system-ui,
        -apple-system,
        BlinkMacSystemFont,
        "Segoe UI",
        Roboto,
        Oxygen,
        Ubuntu,
        Cantarell,
        "Open Sans",
        "Helvetica Neue",
        sans-serif;

    thead {
        tr {
            font-size: 12px;
            background-color: var(--background-color);

            color: #ffffff;
            text-align: left;
        }
    }

    tr.filtering {
        border-bottom: 1px solid #dddddd;

        td {
            background-color: white;
            padding: 0;

            > div {
                color: #797979;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 66px;
                font-size: 10px;
            }
        }

        input {
            border: none;
            width: 100%;
            margin: 0;
            padding: 12px 15px;
            box-sizing: border-box;
        }
    }

    tr.paging {
        
    }

    th,
    td {
        padding: 12px 15px;

        &.id {
            max-width: 10px;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    tbody {
        tr {
            border-bottom: 1px solid #dddddd;

            &:nth-of-type(odd) {
                background-color: #f3f3f3;
            }

            &:last-of-type {
                border-bottom: 2px solid var(--background-color);
            }

            &.active-row {
                font-weight: bold;
                color: var(--background-color);
            }
        }
    }
}


button {
    border: none;
    background: none;
    cursor: pointer;
    width: fit-content;
    padding: 0 10px;
    height: 32px;
    text-align: center;
    box-sizing: border-box;
    
   
    border-radius: 5px;
    margin: 0 4px;

    background-color: var(--background-color);
    color: var(--foreground-color);

    &:hover {
        filter: brightness(85%)
    }

    &[current] {
        background-color: var(--foreground-color);
        color: var(--background-color);
        border: 1px solid var(--background-color);
    }
}

.alert {
    text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    color: var(--font-color--base);
    font-family: var(--font-family--base);
    font-size: var(--font-size--200);
    word-spacing: .025em;
    box-sizing: border-box;
    margin: 0;
    grid-gap: var(--grid-unit);
    border-left: 4px solid var(--neutral--30);
    border-radius: var(--border-radius--sm);
    display: grid;
    font-weight: 400;
    gap: var(--grid-unit);
    grid-template-columns: 20px auto 24px;
    padding: var(--grid-unit);
    padding-bottom: calc(var(--grid-unit)*1.25);
    background-color: var(--warning--10);
    border-color: var(--warning--50);

    .alert__icon {
        box-sizing: border-box;
        color: rgb(240, 192, 63);
        font-family: "Inter", sans-serif;
        font-size: 14px;
        font-weight: 400;
        height: 24px;
        line-height: 21px;
        margin-bottom: 0px;
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 0px;
        padding-top: 2px; 
    }

    .alert__content {
        box-sizing: border-box;
        color: rgb(106, 77, 12);
        font-family: "Inter", sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        margin-bottom: 0px;
        margin-left: 13px;
        margin-right: 0px;
        margin-top: 0px;
        padding-top: 1px;
        text-rendering: optimizespeed;
        word-spacing: 0.35px
    }

    .alert__close-button {
        box-sizing: border-box;
        color: rgb(208, 161, 35);
        cursor: pointer;
        font-family: "Inter", sans-serif;
        font-size: 14px;
        font-weight: 400;
        height: 24px;
        line-height: 21px;
        margin-bottom: 0px;
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 0px;
        padding-bottom: 2px;
        padding-left: 2px;
        padding-right: 2px;
        padding-top: 2px;
        text-rendering: optimizespeed;
        width: 24px;
        word-spacing: 0.35px
    }

    
}