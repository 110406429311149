session-viewer {
    button {
        border: none;
        background: none;
        cursor: pointer;

        svg {
            width: 20px;
            height: 20px;
        }
    }

    .actions {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    select {
        text-align: center;
        padding: 2px;
    }

    td {
        input[type="date"] {
            display: none;
        }

        &[editing] {
            input[type="date"] {
                display: block;
            }

            span {
                display: none;
            }
        }
    }
}


