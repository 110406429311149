.button {
    background: var(--primary);
    border: none;
    color: white;
    font-weight: 500;
    padding: 8px 16px;
    margin: 0 5px;
    border-radius: 30px;
    font-size: 12px;
    display: inline-block;
    cursor: pointer;

    &:focus {
        outline: none;
        box-shadow: 0px 0px 0px 1px black;
    }
}

.fullscreen {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    transition: all 0.25s;
    width: 100%;
    z-index: 999;

    * {
        box-sizing: border-box;
    }

    &[open] {
        background-color: rgba(0, 0, 0, 0.3);
        backdrop-filter: blur(10px);

        .content {
            transform: translateY(0);
        }
    }

    .content {
        max-width: 400px;
        width: 100%;
        border: 1px solid #dadce0;
        position: relative;
        background-color: white;
        border-radius: 4px;
        display: block;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
            Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
            "Segoe UI Symbol";

        // @media @break-phone {
        //     border: none;
        //     max-width: none;
        // }

        .top-close-button {
            position: absolute;
            top: 0;
            right: 0;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: white;
            border: none;
            margin-top: 6px;
            margin-right: 6px;

            div {
                width: 100%;
                height: 100%;
                z-index: 1;
                opacity: 0;
                position: absolute;
            }
            
            svg {
                pointer-events: none;
                opacity: 0.3;
            }

            &:hover {
                background-color: #f2f2f2;

                svg {
                    opacity: 0.9;
                }
            }
        }

        .alt-button {
            border: none;
            background: none;
            color: #0078f1;
        }

        p {
            margin: 6px;
        }

        form {
            position: relative;
            margin-block-end: 0;
        }

        label {
            padding: 10px 0 2px;
            color: hsl(0, 0%, 40%);
            text-transform: uppercase;
            font-size: 12px;
        }

        input {
            // .placeholder({color: rgb(171, 178, 182);});
            background-color: rgb(241, 243, 244);
            border-radius: 4px;
            border: 2px solid white;
            color: black;
            font-family: sans-serif;
            font-size: 16px;
            letter-spacing: 0.5px;
            line-height: 2;
            margin-bottom: 5px;
            outline: none;
            padding: 5px 12px;

            &:-webkit-autofill {
                -webkit-box-shadow: 0 0 0 50px rgb(241, 243, 244) inset;

                &:after {
                    content: "Click to autofill";
                    width: 200px;
                    height: 200px;
                    background-color: pink;
                }
            }

            &:focus {
                border: 2px solid rgb(171, 178, 182);
                background-color: white;
            }

            &:disabled {
                background-color: white;
            }

            &:invalid[blurred] {
                // Only show on elements that we have actually exited
                border-left: 4px solid #d64a48;
            }

            &:valid[touched] {
                border-left: 4px solid #138938;
            }

            // &[state=error][touched] {
            //     border-left: 4px solid #d64a48;
            // }

            &[type="password"] {
                letter-spacing: 2px;
            }
        }

        [name="logo"] {
            display: flex;
            justify-content: center;
            margin-top: -8px;
            position: absolute;
            width: 100%;

            a {
                display: block;
                background: white;
                padding: 0 15px;
            }

            svg {
                height: 13px;
                width: auto;
                padding-bottom: 7px;
            }
        }

        header {
            align-items: center;
            display: flex;
            flex-direction: column;
            height: 120px;
            justify-content: center;

            h1 {
                font-size: 20px;
                text-align: center;
                margin: 0;
            }

            a {
                color: rgb(0, 120, 241);
                text-decoration: none;
            }
        }

        section {
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: relative;
            padding: 0 10px 10px;

            form-group {
                width: 100%;
            }

            input,
            label {
                width: 100%;
                display: block;
            }

            button.primary {
                .button();
            }

            button.secondary {
                .button();
                border: none;
                background:none;
                margin-right: 10px;
                color: var(--primary);
                &:focus {
                    outline: none;
                    box-shadow: 0px 0px 0px 1px black;
                }
            }
        }

        progress-button {
            margin: 10px 0 0 0;
            width: 100%;
        }

        .error-text {
            color: #d64a48;
            font-family: sans-serif;
            font-size: 13px;
            margin-bottom: -3px;
            margin-top: -12px;
            line-height: 15px;

            &:empty {
                margin: 0;
            }
        }
    }
}