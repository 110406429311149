edit-discount-code {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    input[type=text] {
        font-size: 16px;
        width: 300px;
    }

    textarea {
        font-size: 16px;
        width: 300px;
        height: 100px;
    }

    input[type=number] {
        font-size: 16px;
        width: fit-content;
        text-align: center;
    }

    [name=code-box] {
        display: none;
        margin: 10px;

        input {
            font-size: 22px;
            width: 220px;
            text-align: center;
        
        }
    }

    [name=delete] {
        background-color: red;
        cursor: pointer;
    }
}