crm-list {
    tr[unsubscribed] {
        background-color: #f67070; 
    }

    input[name=tags] {
        text-transform: uppercase;
    }


    button[name=remove-tag] {
        background: none;
        color: black;
        padding: 2px;
        display: inline-block;
    }

    td[tags] {
        // display: flex;
        // flex-direction: column;
        white-space: nowrap;
    }
    

    min-width: fit-content;
    
    thead {
        position: sticky;
        top: 0;
    }

    th {
        text-align: center;
        cursor: pointer;
    }
    
    td {
        text-align: center;
        border-right: 1px solid #ccc;
    }

    td[collapsed] {
        width: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        //display: block;
        overflow-wrap: anywhere;
        font-size: 1px;

        text-orientation: sideways;
        writing-mode: vertical-rl;
    }

    th[collapsed] {
        width: 10px !important;
        overflow-wrap: anywhere;
        // transform: rotatez(90deg);

        text-orientation: sideways;
        writing-mode: vertical-rl;
    }

    td[discount-codes] {
        font-size: 12px;
        text-align: left;
    }

    td[email], th[email] { 
        text-align: left;
    }

    td[crmid] {
        width: 30px;
        overflow-wrap: anywhere;
        font-size: 10px;
    }

    .actions {
        white-space: nowrap;
    }
}